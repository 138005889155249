





















































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import moment from "moment";
import html2canvas from "html2canvas";
import printJS from "print-js";

export default defineComponent({
  components: {
    draggable,
    OProgramItemEditor: () =>
      import(
        "@/components/organisms/event/eventProgram/o-program-item-editor.vue"
      ),
  },

  props: {
    type: {
      type: String,
      required: false,
      default: "edit",
    },
    programType: {
      type: String,
      required: false,
      default: "primary",
    },
  },

  setup(props, { root }) {
    const form = ref<any>(null);

    const state = reactive({
      loading: false,
      success: false,
      error: false as boolean | number,
      empty: false,
      loaded: false,
      valid: false,
      drag: false,
      startDate: "",
      endDate: "",
      lecturers: [],
      eventName: "",
      abstractTopics: [],
    });

    const model = reactive({
      days: [] as any,
    });

    const dragOptions = {
      animation: 200,
      disabled: false,
      ghostClass: "ghost",
      scrollSensitivity: 200,
      forceFallback: true,
    };

    const dates = computed(() => {
      const dateArray = [];
      for (
        const d = new Date(state.startDate);
        d <= new Date(state.endDate);
        d.setDate(d.getDate() + 1)
      ) {
        dateArray.push({
          value: moment(d).format("YYYY-MM-DD"),
          text: moment(d).format("dddd - DD MMM YYYY"),
        });
      }
      return dateArray;
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/basic`)
        .then(({ data: { event } }) => {
          state.startDate = event.startDate;
          state.endDate = event.endDate;
          state.eventName = event.name;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const rules: {
      [key: string]: (v: any) => boolean | string;
    } = {
      required: (v) => !!v || `${root.$t("layout.misc.required")}`,
    };

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      if (props.type === "edit") {
        state.loading = true;

        axiosInstance
          .get(`event/${root.$route.params.id}/event-program`, {
            params: { type: props.programType },
          })
          .then(({ data }) => {
            model.days = data.eventDays?.map((ed: any) => ({
              ...ed,
              timeFrames: ed.timeFrames?.map((tf: any) => ({
                ...tf,
                programItems: tf.programItems?.map((pi: any) => ({
                  ...pi,
                  lecturers: pi.lecturers?.map((l: any) => l.id),
                  schedule: pi.schedule?.map((sc: any) => ({
                    ...sc,
                    lecturers: sc.lecturers?.map((l: any) => l.id),
                  })),
                })),
              })),
            }));
          })
          .catch((error) => (state.error = error.response.status))
          .finally(() => (state.loading = false));
      }
    };

    onMounted(fetch);

    const fetchAbstractTopics = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/session-topic`)
        .then(({ data: { sessionTopic } }) => {
          state.abstractTopics = sessionTopic;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.abstractTopics = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchAbstractTopics);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.noEventProgram")}`;
        case 409:
          return `${root.$tc("layout.errors.formDoesNotExist")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const submit = async () => {
      await form.value?.validate();

      if (state.valid) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;

        const data = {
          eventProgram: {
            type: props.programType,
            eventDays: model.days?.map((day: any) => ({
              ...day,
              timeFrames: day.timeFrames?.map((timeFrame: any) => ({
                ...timeFrame,
                programItems: timeFrame.programItems?.map((item: any) => ({
                  ...item,
                  sponsorImage: item.sponsorImage
                    ? typeof item.sponsorImage === "string"
                      ? item.sponsorImage
                      : item.sponsorImage.id
                    : null,
                  schedule: item.schedule?.map((scheduleItem: any) => ({
                    ...scheduleItem,
                    image: scheduleItem.image
                      ? typeof scheduleItem.image === "string"
                        ? scheduleItem.image
                        : scheduleItem.image.id
                      : null,
                  })),
                })),
              })),
            })),
          },
        };

        state.loading = true;

        axiosInstance
          .post(`event/${root.$route.params.id}/event-program`, data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(() => {
            state.success = true;

            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "success",
              message: root.$tc("event.panel.eventProgram.add.success"),
            });
            // fetch();
          })
          .catch((error) => {
            state.error = error.response.status;
            root.$store.commit("snackbar/PUSH_MESSAGE", {
              id: uuid(),
              color: "error",
              message: getErrorMessage(state.error as number),
            });
          })
          .finally(() => (state.loading = false));
      }
    };

    const fetchLecturers = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/lecturers`, {
          params: {
            type: "lecturer" || undefined,
            state: "participant",
            sortBy: ["lastName"],
            sortDesc: [false],
          },
        })
        .then(({ data: { participants } }) => {
          state.lecturers = participants;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchLecturers);

    watch(
      () => state.success,
      () => {
        if (state.success == false) {
          fetch();
        }
      }
    );

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/eventProgram/`
    );

    const generateDoc = () => {
      const htmlString = form.value.$el.innerHTML;

      const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Event program</title></head><body>";
      const postHtml = "</body></html>";
      const html = preHtml + htmlString + postHtml;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });

      const url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      const downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      downloadLink.href = url;

      downloadLink.download = `${state.eventName}.doc`;

      downloadLink.click();

      document.body.removeChild(downloadLink);
    };

    const printProgram = () => {
      printJS({
        type: "html",
        printable: "printable",
        ignoreElements: ["no-print"],
        targetStyles: ["*"],
      });
    };

    const generateImage = () => {
      const node = document.querySelector(".o-event-program");

      html2canvas(node as HTMLElement).then((canvas) => {
        const img = canvas.toDataURL("image/jpeg");

        const downloadLink = document.createElement("a");

        document.body.appendChild(downloadLink);

        downloadLink.href = img;

        downloadLink.download = `${state.eventName}.jpeg`;

        downloadLink.click();

        document.body.removeChild(downloadLink);
      });
    };

    return {
      form,
      uuid,
      state,
      model,
      dragOptions,
      dates,
      rules,
      submit,
      getErrorMessage,
      filesUrl,
      generateDoc,
      printProgram,
      generateImage,
    };
  },
});
